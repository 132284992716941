import React from "react"
import { StoryGroup } from "components"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "JavaScript Foundations",
    url: "https://learnreactui.dev/contents/javascript-foundations",
    img: getEBooksCoverUrl("021_js-foundations"),
  },
  {
    title: "JavaScript'in Temelleri",
    url: "https://learnreactui.dev/contents/javascript-temelleri",
    img: getEBooksCoverUrl("033-jsin-temelleri"),
  },
]

export const LinkMap = {
  "JS Usage Training": "d83c6f28b8a4",

  "Var,Let,Const": "671eaaf104dd",
  "Arrow Function": "63cc425a8966",
  "Regular Function": "63cc425a8966",
  "Anonymous Function": "63cc425a8966",
  "Pure Function": "63cc425a8966",
  "Constructor Function": "63cc425a8966",
  IIFE: "63cc425a8966",
  "High Order Function": "63cc425a8966",
  "Synchronous Function": "63cc425a8966",
  "Asynchronous Function": "63cc425a8966",
  "Generator Function": "63cc425a8966",

  "Falsy ve Truthy": "16b3a427e6b1",
  "Type Coercion": "16b3a427e6b1",
  "Type Conversion": "16b3a427e6b1",
  "Double Exclamation Points": "16b3a427e6b1",
  "Default Parameters": "16b3a427e6b1",

  "Math Operators": "6c4a13a1743",
  "Increment/Decrement Operators": "6c4a13a1743",
  "Compare Operators": "6c4a13a1743",
  "Logical and Bitwise Operators": "6c4a13a1743",
  "Assigment Operators": "6c4a13a1743",
  "Assignment Operators": "6c4a13a1743",
  "Bitwise Shift Operators": "6c4a13a1743",
  "Conditional Operators": "6c4a13a1743",
  "Operator Precedence": "6c4a13a1743",

  Rest: "e5237764319d",

  Spread: "f0ce705f141f",
  "Shallow/Deep Copy": "f0ce705f141f",

  "Function.call/apply": "f0ce705f141f",

  Array: "486b0b9093c8",
  String: "486b0b9093c8",

  "Destruction Assignments": "55baead8e877",

  "Template Literals": "e1355cd585c7",

  "Enhanced Object Literals": "a27ec3036516",

  "For,Do,While Loops": "d891acb20151",
  "For..of": "d891acb20151",
  "Recursive Function": "d891acb20151",

  "Pre-Modules": "50eaca82df89",
  "DIY Modules": "50eaca82df89",
  "CJS Module": "50eaca82df89",
  "AMD Module": "50eaca82df89",
  "ES6 Module": "50eaca82df89",

  NPM: "8f41305ea9a4",

  Map: "5aa3b8b75528",
  Set: "5aa3b8b75528",

  "JS Samples": "ef2831412172",

  ADT: "739fb8df0ce8",
  "Primitive Types": "739fb8df0ce8",
  "Object Types": "739fb8df0ce8",

  Symbol: "8f010abd621a",
}

const stories = [
  {
    title: "JS Pratik Kullanım Eğitim İçeriği",
    postId: "d83c6f28b8a4",
  },
  {
    title: "Neden ? var → let, const",
    postId: "671eaaf104dd",
  },
  {
    title: "JavaScript Veri Türleri",
    postId: "739fb8df0ce8",
  },
  {
    title: "JS Operator",
    postId: "6c4a13a1743",
  },
  {
    title: "JS Fonksiyonlar",
    postId: "63cc425a8966",
  },
  {
    title: "JS Default Parameters",
    postId: "16b3a427e6b1",
  },
  {
    title: "JS Rest",
    postId: "e5237764319d",
  },
  {
    title: "JS Spread",
    postId: "f0ce705f141f",
  },
  {
    title: "JS Destruction Assignments",
    postId: "55baead8e877",
  },
  {
    title: "JS Template Literals?",
    postId: "e1355cd585c7",
  },
  {
    title: "JS Enhanced Object",
    postId: "a27ec3036516",
  },
  {
    title: "JS Loops , For..of",
    postId: "d891acb20151",
  },
  {
    title: "JS Module(IIFE →CJS→AMD →ES6)",
    postId: "50eaca82df89",
  },
  {
    title: "Modules-2 (npm Nedir?)",
    postId: "8f41305ea9a4",
  },
  {
    title: "JS’de ES6 Yardımcı Fonksiyonlar",
    postId: "486b0b9093c8",
  },
  {
    title: "JS ES6 Map/Set Veri Yapıları",
    postId: "5aa3b8b75528",
  },
  {
    title: "JS Symbol",
    postId: "8f010abd621a",
  },
  {
    title: "JS ile Pratikler",
    postId: "ef2831412172",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "JS Practice",
  path: "javascript-usage",
}

const JSUsagePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default JSUsagePage
